export default {
  methods: {
    moduleHasPermission (modules, permission) {
      if (modules && modules.length > 0) {
        let permissions = [];
        modules.forEach(m => {
          for (let index = 0; index < m.module.permissions.length; index++) {
            permissions.push(m.module.permissions[index])
          }
        })
  
        const index = permissions.findIndex(p => p.name == permission)
        return index > -1
      }

      return false
    }
  }
}