<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card class="pb-3">
      <v-card-title class="justify-center">
        <span class="headline">{{$t('AccessLevel')}}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="role.name"
              :readonly="show"
              :label="$t('form_candidate_name')"
              outlined
              name="name"
              type="text"
              dense
              required
              hide-details
              :rules="requiredRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <treeselect
              v-model="role.permissionsIds"
              :readonly="show"
              :multiple="true"
              :options="permissionsVal"
              :placeholder="$t('permissions')"
              value-consists-of="LEAF_PRIORITY"
              required
              :rules="requiredRules"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea
              v-model="role.description"
              :readonly="show"
              outlined
              :label="$t('Roledescription')"
              rows="3"
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
          <v-btn
            :color="'primary'"
            text
            class="mx-2"
            @click="$emit('close')"
          >{{ show ?  $t('close') : $t('cancel')}}</v-btn>
          <v-btn
            v-if="!show"
            :disabled="isLoading"
            color="primary"
            @click="role.id ? update() : save()"
          >{{$t('save')}}</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <error-dialog :dialog="showError" :message="error" @close="showError = false" />
    <progress-dialog :processing="isLoading" />
    <success-dialog :dialog="showSuccess" :message="success" @close="showSuccess = false" />
  </v-dialog>
</template>

<script>
import { CREATE_ROLE_MUTATION, UPDATE_ROLE_MUTATION } from './../graphql/Mutation'
import { GET_PERMISSIONS_BY_TYPE_QUERY } from './../graphql/Query'
import { formatError } from '@/utils'
import { mapActions, mapGetters } from 'vuex'
import ErrorDialog from './../../../components/ErrorDialog.vue'
import ProgressDialog from './../../../components/ProgressDialog.vue'
import SuccessDialog from './../../../components/SuccessDialog.vue'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import moduleHasPermission from "@/mixins/module-has-permission";
export default {
  name: 'AddRoleDialog',
  components: { ErrorDialog, ProgressDialog, SuccessDialog, Treeselect },
  props: {
    dialog: Boolean,
    role: Object,
    show: Boolean
  },
  mixins: [moduleHasPermission],
  data: () => ({
    error: undefined,
    isLoading: false,
    permissions: [],
    showError: false,
    showSuccess: false,
    success: undefined
  }),
  apollo: {
    permissions: {
      query: GET_PERMISSIONS_BY_TYPE_QUERY,
      variables: {
        type: 'entity'
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      modules: "auth/getModules",
    }),
    permissionsVal () {
      return [
        {
          id: "setting",
          label: this.$t('setting'),
          isDisabled: !this.moduleHasPermission(this.modules, "setting_list"),
          children: [
            {
              id: this.getPermissionId("setting_list"),
              label: this.$t('setting_list'),
            },
            {
              id: this.getPermissionId("setting_compony_profile_edit"),
              label: this.$t('institutionprofile'),
              isDisabled: !this.moduleHasPermission(this.modules, "setting_compony_profile_edit")
            },
            {
              id: this.getPermissionId("setting_access_level"),
              label: this.$t('AccessLevel'),
              isDisabled: !this.moduleHasPermission(this.modules, "setting_access_level")
            },
            {
              id: this.getPermissionId("setting_user"),
              label: this.$t('user'),
              isDisabled: !this.moduleHasPermission(this.modules, "setting_user")
            },
          ]
        },
        {
          id: "dashboard",
          label: this.$t('dashboard'),
          children: [
            {
              id: this.getPermissionId("dashboard"),
              label: this.$t('dashboard'),
            },
            {
              id: this.getPermissionId("dashboard_activities"),
              label: this.$t('activities'),
            },
            {
              id: this.getPermissionId("dashboard_user_add"),
              label: this.$t('create_user'),
            },
            {
              id: this.getPermissionId("dashboard_client_add"),
              label: this.$t('create_customer'),
            },
            {
              id: this.getPermissionId("dashboard_candidate_add"),
              label: this.$t('create_candidate'),
            }
          ]
        },
        {
          id: "vacancies",
          label: this.$t('role_vacancy_label'),
          isDisabled: !this.moduleHasPermission(this.modules, "vacancies_list"),
          children: [
            {
              id: this.getPermissionId("vacancies_list"),
              label: this.$t('role_vacancy_list'),
              isDisabled: !this.moduleHasPermission(this.modules, "vacancies_list")
            },
            { id: this.getPermissionId("publish"), label: "Publicar Vagas", isDisabled: this.show },
            {
              id: this.getPermissionId("vacancies_add"),
              label: this.$t('role_vacancy_add'),
              isDisabled: !this.moduleHasPermission(this.modules, "vacancies_add")
            },
            {
              id: this.getPermissionId("vacancies_show_vacancy"),
              label: this.$t('role_vacancy_view'),
              isDisabled: !this.moduleHasPermission(this.modules, "vacancies_show_vacancy")
            },
            {
              id: this.getPermissionId("vacancies_edit_vacancy"),
              label: this.$t('role_vacancy_edit'),
              isDisabled: !this.moduleHasPermission(this.modules, "vacancies_edit_vacancy")
            },
            {
              id: this.getPermissionId("vacancies_remove"),
              label: this.$t('role_vacancy_remov'),
              isDisabled: !this.moduleHasPermission(this.modules, "vacancies_remove")
            }
          ]
        },
        {
          id: "applications",
          label: this.$t('role_candidancy_label'),
          isDisabled: !this.moduleHasPermission(this.modules, "applications_list"),
          children: [
            {
              id: this.getPermissionId("applications_list"),
              label: this.$t('role_candidancy_list'),
              isDisabled: !this.moduleHasPermission(this.modules, "applications_list")
            },
            {
              id: this.getPermissionId("send_test"),
              label: this.$t('role_candidancy_send_test'),
              isDisabled: !this.moduleHasPermission(this.modules, "send_test")
            },
            {
              id: this.getPermissionId("schedule_interview"),
              label: this.$t('role_candidancy_schedule'),
              isDisabled: !this.moduleHasPermission(this.modules, "schedule_interview")
            }
          ]
        },
        {
          id: "ranking",
          label: "Ranking",
          isDisabled: !this.moduleHasPermission(this.modules, "ranking_list"),
          children: [
            {
              id: this.getPermissionId("ranking_list"),
              label: "Ranking",
              isDisabled: !this.moduleHasPermission(this.modules, "ranking_list")
            },
            {
              id: this.getPermissionId("ranking_schedule_interview"),
              label: this.$t('role_ranking_schedule'),
              isDisabled: !this.moduleHasPermission(this.modules, "ranking_schedule_interview")
            },
            {
              id: this.getPermissionId("ranking_show_profile"),
              label: this.$t('role_ranking_profile'),
              isDisabled: !this.moduleHasPermission(this.modules, "ranking_show_profile")
            }
          ]
        },
        {
          id: "clients",
          label: this.$t('role_client_label'),
          isDisabled: !this.moduleHasPermission(this.modules, "clients_list"),
          children: [
            { 
              id: this.getPermissionId("clients_list"), 
              label: this.$t('role_client_list'), 
              isDisabled: !this.moduleHasPermission(this.modules, "clients_list")
            },
            {
              id: this.getPermissionId("client_add"),
              label: this.$t('role_client_add'),
              isDisabled: !this.moduleHasPermission(this.modules, "client_add")
            },
            {
              id: this.getPermissionId("client_show"),
              label: this.$t('role_client_view'),
              isDisabled: !this.moduleHasPermission(this.modules, "client_show")
            },
            {
              id: this.getPermissionId("client_edit"),
              label: this.$t('role_client_edit'),
              isDisabled: !this.moduleHasPermission(this.modules, "client_edit")
            },
            {
              id: this.getPermissionId("client_delete"),
              label: this.$t('role_client_remov'),
              isDisabled: !this.moduleHasPermission(this.modules, "client_delete")
            }
          ]
        },
        {
          id: "database",
          label: this.$t('role_bd_label'),
          isDisabled: !this.moduleHasPermission(this.modules, "database_list"),
          children: [
            {
              id: this.getPermissionId("database_list"),
              label: this.$t('role_bd_list'),
              isDisabled: !this.moduleHasPermission(this.modules, "database_list")
            },
            {
              id: this.getPermissionId("database_send_vacancy"),
              label: this.$t('role_bd_send_test'),
              isDisabled: !this.moduleHasPermission(this.modules, "database_send_vacancy")
            }
          ]
        },
        {
          id: "interviews",
          label: this.$t('role_interview_label'),
          isDisabled: !this.moduleHasPermission(this.modules, "interviews_list"),
          children: [
            {
              id: this.getPermissionId("interviews_list"),
              label: this.$t('role_interview_list'),
              isDisabled: !this.moduleHasPermission(this.modules, "interviews_list")
            }
          ]
        },
        {
          id: "tests",
          label: this.$t('role_test_label'),
          isDisabled: !this.moduleHasPermission(this.modules, "tests_list"),
          children: [
            { 
              id: this.getPermissionId("tests_list"), 
              label: this.$t('role_test_list'), 
              isDisabled: !this.moduleHasPermission(this.modules, "tests_list") 
            },
            {
              id: this.getPermissionId("tests_create"),
              label: this.$t('role_test_add'),
              isDisabled: !this.moduleHasPermission(this.modules, "tests_create")
            },
            {
              id: this.getPermissionId("tests_preview"),
              label: this.$t('role_test_view'),
              isDisabled: !this.moduleHasPermission(this.modules, "tests_preview")
            },
            {
              id: this.getPermissionId("tests_update"),
              label: this.$t('role_test_edit'),
              isDisabled: !this.moduleHasPermission(this.modules, "tests_update")
            },
            {
              id: this.getPermissionId("tests_delete"),
              label: this.$t('role_test_remov'),
              isDisabled: !this.moduleHasPermission(this.modules, "tests_delete")
            },
            {
              id: this.getPermissionId("tests_add_question"),
              label: this.$t('role_test_add_q'),
              isDisabled: !this.moduleHasPermission(this.modules, "tests_add_question")
            }
          ]
        }
      ];
    },
    requiredRules () {
      return [v => !!v || this.$t("form_user_valid")]
    }
  },
  methods: {
    ...mapActions({
      setRole: 'role/setRole',
      updateRoleState: 'role/updateRole'
    }),
    getPermissionId (key) {
      return this.permissions.length > 0 ? this.permissions.find(p => p.name == key).id : key
    },
    async save () {
      this.isLoading = true
      try {
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_ROLE_MUTATION,
          variables: {
            roleInput: this.role
          }
        })
        this.setRole(data.createRole)
        this.success = this.$t('rolecreatSuccess');
        this.showSuccess = true
        this.$emit('close')
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
    async update () {
      this.isLoading = true
      try {
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_ROLE_MUTATION,
          variables: {
            id: this.role.id,
            roleInput: this.role
          }
        })
        this.updateRoleState(data.updateRole)
        this.success =  this.$t('roleupdatedSuccess');
        this.showSuccess = true
        this.$emit('close')
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style>
.v-input--selection-controls {
  margin-top: 0;
  margin-bottom: 0;
}

.vue-treeselect__control {
  border: 1px solid #9e9e9e;
  border-radius: 3px;
}

.vue-treeselect__placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.vue-treeselect:not(.vue-treeselect--disabled):not(.vue-treeselect--focused)
  .vue-treeselect__control:hover {
  border: 1px solid #000;
}

/* .v-treeview-node__root {
  padding-left: 0;
  padding-right: 0;
} */
</style>